import React from "react";

import Input from "../Input";
import { SIZES } from "../utils/constant";

const DropdownSearch = ({
  placeholder,
  searchString,
  setSearchString,
  placement,
}) => {
  const searchContainerClassName = `sticky p-2 bg-white border ${
    placement === "top" ? "top-0 mb-2" : "bottom-0 mt-2"
  }`;
  return (
    <div className={searchContainerClassName}>
      <Input
        placeholder={placeholder}
        autoFocus={true}
        size={SIZES.SM}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
    </div>
  );
};
export default DropdownSearch;
