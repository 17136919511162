import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSendBirdData } from "services/Chat";
import { getSessionCodes } from "services/Information";
import { fetchVidoSequence } from "services/Training";

export const fetchVidoSeq = createAsyncThunk("fetchSequence", async (data) => {
  const response = await fetchVidoSequence(data);
  return response.data;
});

export const fetchSessionCodes = createAsyncThunk(
  "fetchSessionCodes",
  async () => {
    const response = await getSessionCodes();
    return response.data.sessionCodes;
  },
);

export const fetchSendBirdData = createAsyncThunk(
  "fetchSendBirdData",
  async () => {
    const response = await getSendBirdData();
    return response.data;
  },
);

export const initialState = {
  avatar: "",
  userName: "",
  email: "",
  // authority: ['Technician'],
  authority: [],
  pages: [],
  loading: false,
  isProfileComplete: false,
  additionalInformation: false,
  userStatus: "PENDING",
  profileAvailable: "false",
  clinicLocations: [],
  role: "",
  clinicId: "",
  namespace: "",
  nextVideo: 0,
  videoSeq: [],
  featuresAccess: {
    ehr: false,
    scheduling: false,
    talentBoard: false,
    training: false,
  },
  sendBirdData: {
    sessionToken: "",
    user: "",
    expiresAt: "",
  },
  isSendBirdDataLoading: true,
  sessionCodes: [],
  isSessionCodesLoaded: false,
  isSessionCodesLoading: false,
};

export const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (_, action) => {
      return action.payload;
    },
    setNextVideo: (state, action) => {
      state.nextVideo = action.payload;
    },
    setClinicLocations: (state, action) => {
      state.clinicLocations = action.payload;
    },
    setProfileStatus: (state, action) => {
      state.isProfileComplete = action.payload;
    },
    setRoleAcces: (state, action) => {
      state.roleAccess = action.payload;
    },
    setProfile: (state, action) => {
      state.profileAvailable = action.payload;
    },
    setAdditionalInformation: (state, action) => {
      state.additionalInformation = action.payload;
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setNav: (state, action) => {
      state.nav = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setClinicId: (state, action) => {
      state.clinicId = action.payload;
    },
    setNameSpace: (state, action) => {
      state.namespace = action.payload;
    },
    setFeaturesAccess: (state, action) => {
      state.featuresAccess = action.payload;
    },
    userLoggedOut: () => initialState,
  },
  extraReducers: {
    [fetchVidoSeq.fulfilled]: (state, action) => {
      state.videoSeq = action.payload;
    },
    [fetchVidoSeq.pending]: (state) => {},
    [fetchVidoSeq.rejected]: (state) => {},
    [fetchSessionCodes.fulfilled]: (state, action) => {
      state.sessionCodes = action.payload;
      state.isSessionCodesLoaded = true;
    },
    [fetchSessionCodes.pending]: (state) => {
      state.isSessionCodesLoaded = false;
      state.isSessionCodesLoading = true;
    },
    [fetchSessionCodes.rejected]: (state) => {},
    [fetchSendBirdData.fulfilled]: (state, action) => {
      const sendBirdData = {
        sessionToken: action.payload?.accessToken?.token,
        user: action.payload?.accessToken?.user,
        expiresAt: action.payload?.accessToken?.expires_at,
      };
      state.sendBirdData = sendBirdData;
      state.isSendBirdDataLoading = false;
    },
    [fetchSendBirdData.pending]: (state) => {
      state.isSendBirdDataLoading = true;
    },
    [fetchSendBirdData.rejected]: (state) => {
      state.isSendBirdDataLoading = false;
    },
  },
});

export const {
  setUser,
  setNextVideo,
  setNav,
  setProfileStatus,
  setProfile,
  setUserStatus,
  setNameSpace,
  setFeaturesAccess,
  setAdditionalInformation,
  setClinicId,
  setRole,
  setRoleAcces,
  setEmail,
  setClinicLocations,
} = userSlice.actions;

export default userSlice.reducer;
