import "./polyfill.js";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-dates/initialize";

import TawkButton from "components/wrapper/TawkButton.js";
import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer
      position="top-center"
      closeButton={false}
      hideProgressBar={true}
      toastClassName="bg-primary"
    />
    <TawkButton />
    <App />
  </React.StrictMode>,
);

reportWebVitals();
